// @ts-check
/// <reference lib="webworker" />

/**
 * The JSON we get from the PushEvent data
 * @typedef {Object} EventData
 * @property {string} title - Indicates whether the Courage component is present.
 * @property {string} body - Indicates whether the Courage component is present.
 * @property {string} icon - Indicates whether the Courage component is present.
 */

/**
 * @param {PushEvent} event - Foo
 * @returns void
 */
function pushNotificationHandler(event) {
  const self = /** @type {ServiceWorkerGlobalScope} */ (
    /** @type {unknown} */ (globalThis.self)
  );

  const notificationData = event.data?.json();
  void self.registration.showNotification(notificationData.title, {
    body: notificationData.body,
    icon: notificationData.icon,
    tag: notificationData.tag,
  });
}
//
self.addEventListener("push", pushNotificationHandler);
